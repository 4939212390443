export const educationArray = [
	{
		id:"3",
		major:"Web Development (Certificate)",
		schoolName:"The Odin Project",
		location:"New York, NY",
		gradYear:"2020",
	},
	{
		id:"2",
		major:"Audio Engineering (Certificate)",
		schoolName:"Quad Recording Studios",
		location:"New York, NY",
		gradYear:"2011",
	},
	{
		id:"1",
		major:"Finance (Bachelor of Science)",
		schoolName:"Iowa State University",
		location:"Ames, IA",
		gradYear:"2006",
	}
]